export default {
  error_occurred: "Jiddispjacini, seħħ żball. Jekk jogħġbok erġa' pprova fi ftit mument.",
  new_content_available: "Hemm aġġornament ġdid tal-paġna, jekk jogħġbok ikklikkja 'Aġġorna' biex terġa' tgħabbi l-paġna.",
  refresh_button_title: 'Aġġorna',
  force_reload_message: "Jiddispjacini, seħħ żball. It-tim tagħna diġà qed jaħdem fuqha. Jekk jogħġbok ipprova tagħbija mill-ġdid il-websajt f'mument.",
  sign_up: 'Oħloq kont',
  service: 'Servizz',
  employee: 'Impjegat',
  select_employee: 'Agħżel persuna',
  employees: 'Impjegati',
  services: 'Servizzi',
  duration: "Tul ta' żmien",
  from: 'Minn',
  select_date: 'Agħżel data',
  no_account_question: "Għad m'għandekx kont?",
  name: 'Isem',
  surname: 'Kunjom',
  name_and_surname: 'Isem sħiħ',
  phone: 'Telefon',
  password: 'Password',
  field_is_required: 'Dan il-qasam huwa meħtieġ.',
  value_is_too_long: 'Il-valur huwa twil wisq.',
  value_is_too_short: 'Il-valur huwa qasir wisq.',
  signup_was_successful: 'Ir-reġistrazzjoni kienet suċċess. Jidħol fil-panel.',
  signup_has_failed: "Ir-reġistrazzjoni falliet. Jekk jogħġbok erġa' pprova fi ftit mument.",
  email_is_taken: 'Il-kont bl-indirizz tal-email mogħti diġà jeżisti. Jekk insejt il-password tiegħek, uża l-għażla tat-tfakkira.',
  email_or_password_incorrect: 'L-indirizz elettroniku jew il-password mhumiex korretti. Jekk insejt il-password tiegħek, uża l-għażla tat-tfakkira.',
  account_not_found: 'Kont ma nstabx.',
  page_not_found: 'Oops! Il-paġna li kont qed tfittex ma setgħetx tinstab :(',
  go_back_to_main_page: "Mur lura għall-paġna ewlenija u erġa' pprova.",
  bookings: 'Prenotazzjonijiet',
  account: 'Il-kont tiegħi',
  date: 'Data',
  cancel: 'Ikkanċella',
  date_from: 'Data minn',
  date_to: 'Iddata sa',
  nothing_found_here: 'Ma nstab xejn.',
  old_password: 'Password l-antika',
  remove: 'Ħassar',
  phone_is_invalid: 'In-numru tat-telefon mhuwiex korrett.',
  book: 'Ktieb',
  sign_up_2: 'Oħloq kont',
  log_in: 'Idħol',
  your_email_address: 'L-indirizz eletroniku tiegħek',
  select: 'Agħżel',
  filters: 'Filtri',
  expand: 'Jespandu',
  collapse: 'Kollass',
  bookings_drawer_title: 'Agħżel filtri biex turi biss il-prenotazzjonijiet li qed tfittex.',
  clear_filters: 'Irrisettja l-filtri',
  show_more: 'Uri aktar',
  dont_you_remember_password: 'Insejt il-password?',
  password_information: "Il-password għandha tikkonsisti f'mill-inqas 8 karattri, ikun fiha ittra kbira, numru, u karattru speċjali.",
  forgotten_password_button: 'Ibgħat link biex tbiddel il-password tiegħek',
  do_you_already_have_an_account: 'Diġà għandek kont?',
  reset_password_title: 'Issettja password ġdida',
  reset_password_subtitle: 'Daħħal il-password il-ġdida tiegħek hawn taħt.',
  reset_password_button: 'Issejvja l-password il-ġdida',
  profile: 'Profil',
  logout: 'Iffirma',
  cancel_booking: 'Ikkanċella l-prenotazzjoni tiegħek',
  status_approved: 'Approvat',
  status_payment: 'Jistenna l-ħlas',
  status_done: 'Imlesta',
  status_cancelled: 'Ikkanċellat',
  cancel_booking_dialog_title: 'Trid tikkanċella l-prenotazzjoni tiegħek?',
  cancel_booking_dialog_information: 'Din l-azzjoni ma tistax titneħħa!',
  cancel_booking_dialog_button: 'Iva, tikkanċella l-prenotazzjoni tiegħi',
  my_profile: 'Il-profil Tiegħi',
  my_data: 'Id-dettalji tiegħi',
  upload_new_photo: 'Żid ritratt ġdid',
  birthday: 'Birthday',
  save_changes: 'Issejvja l-bidliet',
  phone_number: 'Numru tat-telefon',
  directional: 'Kodiċi taż-żona',
  save_new_password: 'Issejvja l-password il-ġdida',
  new_password: 'Password gdida',
  password_change: 'Biddel il-password',
  view: 'Ara',
  services_2: 'Servizzi',
  accept_terms_and_conditions_1: 'Billi tikklikkja l-għażla hawn taħt, naqbel mal-',
  accept_terms_and_conditions_2: 'Termini u Kundizzjonijiet',
  accept_terms_and_conditions_3: 'u nikkonferma li qrajt il-',
  accept_terms_and_conditions_4: "Dikjarazzjoni ta' Privatezza.",
  to: 'Biex',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Idħol',
  email_is_incorrect: 'L-indirizz elettroniku mhuwiex korrett.',
  logged_out: 'Ġejt iffirmat',
  booking_dialog_date_is_already_taken: 'Il-ħin magħżul mhuwiex disponibbli. Taħseb li tagħżel post differenti?',
  data_search: 'Tiftix għal data. Stenna...',
  no_employees: 'L-ebda impjegat',
  no_services: 'Ebda servizzi',
  no_categories: 'Ebda kategoriji',
  no_bookings: 'Bookings ma nstabux.',
  no_subscriptions: 'Ma nstab l-ebda abbonament.',
  female: 'Mara',
  male: 'Raġel',
  other: 'Oħrajn',
  no_numeric_in_password: 'L-ebda karattru numeriku fil-password.',
  no_lowercase_in_password: 'L-ebda ittra żgħira fil-password.',
  no_uppercase_in_password: 'L-ebda ittra kbira fil-password.',
  no_special_character: 'Ebda karattru speċjali.',
  min_n_characters: "In-numru minimu ta' karattri huwa @{min}.",
  max_n_characters: "In-numru massimu ta' karattri huwa @{max}.",
  email: 'E-mail',
  reset_password_fail_text: 'Il-password ma nbidlitx. Il-link probabbilment skadiet.',
  reset_password_success_title: 'Il-password tiegħek ġiet mibdula.',
  reset_password_success_text: "Tista' tidħol bil-password il-ġdida tiegħek.",
  account_activation: 'Attivazzjoni tal-kont',
  account_activation_success: 'Il-kont ġie attivat.',
  account_activation_fail: "Kien hemm problema meta attivat il-kont tiegħek. Jidher li l-kont diġà huwa attiv jew il-link m'għadhiex valida.",
  account_activation_processing: 'Attivazzjoni tal-kont tiegħek. Stenna...',
  account_activation_resend_title: 'Il-kont ma ġiex attivat',
  account_activation_resend_text: 'Attiva l-kont tiegħek billi tikklikkja l-link tal-attivazzjoni fl-email li bgħattilek.',
  account_activation_resend_button: 'Ibgħat mill-ġdid',
  confirm_send_activate_link_dialog_subtitle: 'Bgħatna email bil-link tal-attivazzjoni għall-indirizz tal-email ipprovdut qabel.',
  file_is_too_large: 'Il-fajl magħżul huwa kbir wisq.',
  appointment_title: 'Agħżel persuna, data u ħin tal-prenotazzjoni',
  others: 'Oħrajn',
  countryLabel: 'Pajjiż',
  requiredMessages: 'Huwa meħtieġ numru tat-telefon',
  date_of_visit: 'Data',
  hour_of_visit: 'Ħin',
  thank_you_for_booking: 'Il-prenotazzjoni tiegħek',
  no_free_hours: "Ebda slots ta' ħin disponibbli fil-ġurnata magħżula.",
  any_person: 'Għażla każwali',
  password_changed: 'Il-password tiegħek ġiet mibdula',
  incorrect_old_password: 'Il-password l-antika mhix korretta',
  error404: 'Żball 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'kollox sew',
  web_page_temporary_disabled: 'Jiddispjacini! Din il-paġna ġiet diżattivata temporanjament.',
  profile_update_success: 'Id-dettalji tiegħek ġew salvati.',
  form_is_invalid: "Il-formola fiha żbalji. Jekk jogħġbok iċċekkja l-oqsma enfasizzati u erġa' pprova.",
  search: 'Fittex',
  pay_online_button_title: 'Ħlas Online',
  account_not_activated_info: 'L-indirizz tal-email ma ġiex ikkonfermat. Ikkonferma l-indirizz elettroniku tiegħek billi tikklikkja l-link fil-messaġġ mibgħut.',
  paid: 'Imħallas',
  payments: 'Ħlasijiet',
  payment_service: 'Servizz',
  payment_date: 'Data',
  payment_price: 'Prezz',
  payment_success_title: 'Il-ħlas kien suċċess, grazzi!',
  payment_fail_title: "Il-ħlas falla, jekk jogħġbok erġa' pprova.",
  pay_again: 'Ħlas mill-ġdid',
  newsletter_subscribe_success: 'Grazzi! L-indirizz tal-email tiegħek ġie kkonfermat.',
  newsletter_subscribe_fail: "Kien hemm problema meta tiġi attivata l-indirizz tal-email tiegħek. Jidher li l-indirizz tal-email huwa diġà attiv jew il-link m'għadux validu.",
  newsletter_send_again: 'Ibgħat mill-ġdid',
  newsletter_send_again_success: 'Il-link tal-attivazzjoni reġgħet intbagħtet.',
  send_again_fail: "Kien hemm problema biex tiġġenera link ta' attivazzjoni ġdida, forsi l-indirizz tal-email tiegħek huwa diġà attiv jew għadek kif iġġenera l-link.",
  newsletter_email_is_used: 'L-indirizz tal-email diġà jinsab fil-lista, jekk l-indirizz ma jkunx ġie kkonfermat, nistgħu nibagħtu mill-ġdid il-link tal-attivazzjoni.',
  newsletter_unsubscribe_success: 'Grazzi! L-indirizz tal-email tiegħek tneħħa.',
  newsletter_unsubscribe_fail: 'Kien hemm problema biex tħassar l-indirizz tal-email. Jidher li l-indirizz tal-email diġà tħassar jew il-link skadiet.',
  booking_payments: 'Prenotazzjonijiet',
  simple_store_product_transactions: 'Prodotti',
  payment_type_select_box: "Agħżel it-tip ta 'ħlas",
  payment_description: 'Deskrizzjoni',
  add_to_cart: 'Żid mal-karrettun',
  remove_from_cart: 'Neħħi mill-karrettun',
  save: 'Ħlief',
  bookings_cart_summary: 'Sommarju tal-prenotazzjoni',
  today: 'Illum',
  fill_booking_details_title: 'Daħħal id-dettalji',
  create_an_account_question: 'Oħloq kont u ibgħat il-password fl-indirizz elettroniku',
  value_is_incorrect: 'Dan il-valur mhuwiex validu.',
  first_available_date: "Slots ta' ħin disponibbli fuq",
  service_location_1: 'Indirizz',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Oħrajn',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Post',
  booking_not_found: 'Booking ma nstabx.',
  booking_canceled: 'Il-prenotazzjoni ġiet ikkanċellata.',
  booking_canceled_fail: 'Din il-prenotazzjoni ma tistax tiġi kkanċellata.',
  day: 'Jum',
  week: 'Ġimgħa',
  month: 'Xahar',
  year: 'Sena',
  booking_plural_1: 'Booking',
  booking_plural_2: 'Prenotazzjonijiet',
  booking_plural_3: 'Prenotazzjonijiet',
  booking_plural_4: 'Prenotazzjonijiet',
  booking_plural_5: 'Prenotazzjonijiet',
  day_plural_1: 'Jum',
  day_plural_2: 'Jiem',
  day_plural_3: 'Jiem',
  day_plural_4: 'Jiem',
  day_plural_5: 'Jiem',
  week_plural_1: 'Ġimgħa',
  week_plural_2: 'Ġimgħat',
  week_plural_3: 'Ġimgħat',
  week_plural_4: 'Ġimgħat',
  week_plural_5: 'Ġimgħat',
  month_plural_1: 'Xahar',
  month_plural_2: 'Xhur',
  month_plural_3: 'Xhur',
  month_plural_4: 'Xhur',
  month_plural_5: 'Xhur',
  year_plural_1: 'Sena',
  year_plural_2: 'Snin',
  year_plural_3: 'Snin',
  year_plural_4: 'Snin',
  year_plural_5: 'Snin',
  tax: 'ID tal-VAT',
  tax_prefix: 'Prefiss',
  get_company_data: 'Fittex',
  get_company_data_failed: 'Id-dejta ma setgħetx titniżżel.',
  company_name: 'Isem tal-Kumpanija',
  street_and_number: 'Triq u numru',
  postcode: 'kodiċi postali',
  city: 'Belt',
  country: 'Pajjiż',
  subscribe: 'Abbona',
  recurrent_fee_info: 'Billed kull',
  one_time_fee_info: "Ħlas ta' darba.",
  new_account_title: 'Oħloq kont ġdid',
  payment_data_title: 'Dettalji tal-ħlas',
  billing_data_checkbox_question: 'Dettalji tal-kontijiet',
  confirmation_modal_header: 'L-azzjoni teħtieġ konferma',
  confirmation_modal_warning: 'Żgur li trid tkompli?',
  no_way_to_undo_action: 'Din l-azzjoni ma tistax titneħħa!',
  delete_payment_method: 'Neħħi l-karta',
  add_payment_method: 'Żid il-karta',
  fill_card_details: 'Imla d-dettalji tal-ħlas.',
  subscriptions: 'Abbonamenti',
  subscription: 'Abbonament',
  invoice_number: 'Fattura',
  number: 'Numru',
  status: 'Status',
  details: 'Dettalji',
  created_at: 'Maħluq',
  subscription_status_initiated: 'Mhux imħallas',
  subscription_status_active: 'Attiva',
  subscription_status_inactive: 'Inattivi',
  subscription_status_canceled: 'Ikkanċellat',
  ends_at: 'Tispiċċa fi',
  ends_at_default: 'Validu sa tmiem il-perjodu tal-kontijiet',
  select_subscription_to_pay: 'Agħżel abbonament biex tħallas.',
  pay_with_subscription: "Ħlas b'abbonament",
  bookings_paid_by_subscription_success_message: 'Imħallas',
  bookings_not_paid_by_subscription_error_message: 'Il-prenotazzjoni ma setgħetx titħallas bl-abbonament magħżul.',
  stripe: 'Strixxa',
  provider_tag: 'Metodu',
  card: 'Card',
  cash: 'Flus kontanti',
  transfer: 'Trasferiment',
  promo_code_label: "Kodiċi ta' skont",
  promo_code_invalid_message: "Il-kodiċi ta' skont ipprovdut mhuwiex validu.",
  payment_method_setup_error: 'Kien hemm problema bil-karta tiegħek. Il-bank tiegħek irrifjuta li jawtorizza l-karta għal tranżazzjonijiet aktar tard. Jekk jogħġbok ikkuntattja lill-bank tiegħek.',
  active_subscription_missing: "Il-prenotazzjoni għal dan is-servizz teħtieġ abbonament attiv. Sfortunatament, m'għandekx abbonament x'tuża.",
  area_code: 'Kodiċi taż-żona',
  usage: 'Użu',
  create_account_in_stripe_failed: "L-operatur tal-ħlas irrifjuta d-dejta tal-abbonament tiegħek. Jekk jogħġbok iċċekkja l-korrettezza tal-formola (numru ta' identifikazzjoni tat-taxxa, numru tat-telefon, indirizz elettroniku).",
  additional_auth_required: 'Il-bank tiegħek jeħtieġ awtorizzazzjoni addizzjonali',
  general_payment_error: "Kien hemm problema bil-ħlas tiegħek. Forsi l-karta tiegħek ma tappoġġjax abbonamenti, għandha limiti ta 'xiri, jew m'għandhiex fondi biżżejjed biex tħallas għall-abbonament. Jekk jogħġbok ikkuntattja lill-bank tiegħek jew agħżel metodu ta' ħlas ieħor.",
  subscription_set_successfully: "Abbonament stabbilit b'mod korrett.",
  booking_created: 'Prenotazzjoni ġdida',
  booking_updated: 'Bidla tal-prenotazzjoni',
  booking_deleted: "Tħassir ta' prenotazzjoni",
  booking_reminder: 'Tifkira tal-prenotazzjoni',
  email_notifications: 'Notifiki bil-posta elettronika',
  sms_notifications: 'Notifiki SMS',
  save_user_preferences: 'Issejvja l-preferenzi',
  free_spots: 'Fadal spots',
  no: 'Nru',
  yes: 'Iva',
  p24_inactive: 'L-operatur tal-ħlas Przelewy24 ma jistax jintuża. Jekk jogħġbok ikkuntattja l-operatur tal-ħlas tiegħek.',
  stripe_customer_invalid: 'ID tal-Klijent Stripe invalida. Il-metodu tal-ħlas ma jistax jiġi mehmuż mal-klijent.',
  stripe_resource_invalid: 'Il-prodott magħżul mhuwiex disponibbli. Jekk jogħġbok iċċekkja d-disponibbiltà tal-prodott mal-operatur tal-ħlas.',
  account_blocked_message: 'Il-kont tiegħek mhuwiex attiv. Jekk jogħġbok ikkuntattja lill-amministratur.',
  time_zone: 'Żona tal-ħin',
  no_spots_left: "Int ilħaqt in-numru massimu ta' postijiet disponibbli.",
  employee_auth_required: 'Jekk jogħġbok idħol fil-kont tiegħek jew uża indirizz elettroniku differenti. Il-kontijiet tal-impjegati jeħtieġu illoggjar.',
  subpage_auth_required: 'Jekk jogħġbok idħol fil-kont tiegħek biex tara t-tab.',
  gross: 'Ammont gross.',
  subscription_login_info: 'Diġà għandek kont? Jekk jogħġbok idħol hawn taħt biex timla d-dejta tiegħek.',
  change_date_time: 'Iskeda mill-ġdid',
  promo_code_checkbox: "Għandi kodiċi ta' skont",
  booking_promo_code_label: "Daħħal il-kodiċi ta' skont",
  select_hour: 'Agħżel il-ħin',
  id: 'Id',
  booking_rescheduled: 'Il-prenotazzjoni tiegħek ġiet mibdula.',
  booking_rescheduled_fail: 'Din il-prenotazzjoni ma tistax tinbidel.',
  max_user_bookings_error: "Qbiżt in-numru massimu ta' prenotazzjonijiet. Jekk jogħġbok agħżel servizz ieħor jew ikkuntattjana.",
  in_total: 'Total',
  company: 'Kumpanija',
  individual: 'Persuna privata',
  bulk_payment: 'Ħlas bl-ingrossa',
  make_bulk_payment: 'Ħlas bl-ingrossa',
  simple_store_product_confirmation_title: 'Imla l-formola tax-xiri',
  select_service_type: "Agħżel għażla ta' servizz",
  add_to_calendar_button_label: 'Żid mal-Kalendarju',
  login_to_use_service_with_subscription: 'Jekk jogħġbok idħol fil-kont tiegħek biex tibbukkja appuntament għal dan is-servizz.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Id-data magħżula mhiex disponibbli. Jekk jogħġbok agħżel data oħra libera. F\'każ ta\' prenotazzjoni ta\' dati multipli, kun żgur li d-dati ma jeskludux lil xulxin.',
  select_location_calendar_warning: 'Agħżel post biex ittella’ l-ħinijiet disponibbli.',
  selectable_location_online_title: 'Fuq l-Internet',
  change: 'Biddel',
  show_less_hours_button: 'Uri inqas sigħat',
  show_more_hours_button: 'Uri aktar sigħat',
  add_another_button_title: 'Żid ieħor',
  close: 'Agħlaq',
  selected_time_slots: 'Ħinijiet magħżula',
  select_location: 'Agħżel il-post',
  captcha_invalid_error_notification: 'Il-verifika tal-Captcha falliet. Ipprova terġa’ ttella’ l-paġna jekk il-problema tippersisti.',
  verify_code_incorrect_error: 'Il-kodiċi daħal mhux korrett.',
  verify_code_too_many_requests: 'Ma nistgħux niġġeneraw kodiċi ġdid bħalissa. Ipprova aktar tard jew uża l-kodiċi li diġà bgħatna.',
  send_again_button_title: 'Ibgħat mill-ġdid',
  verify_title: 'Ivverifika',
  verify_code_title: 'Daħħal il-kodiċi ta’ verifika',
  verify_code_description: 'Bgħatna kodiċi ta’ verifika ta’ 6 ċifri fuq l-email tiegħek. Jekk jogħġbok daħħlu hawn taħt:',
  verification_invalid_error_notification: 'Verifika invalida. Erġa pprova aktar tard.'
}
